.AppIcon {
  width: 35px;
  height: 46px;
  display: block;
  margin: 0 auto;
  padding-top: 45px;
  padding-bottom: 37px;
}

body {
  background: #FFF;
}

.CertificateBlock {
  background: #FFF;
  height: 100%;
}

.MessageIcon {
  width: 114px;
  height: 69px;
  display: block;
  margin: 0 auto;
  padding-right: 10px;
}

.TitleText {
  font-family: 'Gilroy Semibold';
  font-size: 36px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.DownloadError {
  font-family: 'Gilroy Semibold';
  font-size: 19px;
  line-height: 20px;
  min-height: 20px;
  text-align: center;
  color: #a10909;
}
