.AdvicesBlock {
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.AdvicesImage {
  width: auto;
  width: 61px;
  margin: 27px;
}

.AdvicesText {
  font-family: 'Gotham Pro Regular';
  font-size: 16px;
  line-height: 22px;
  padding-top: 7px;
  text-align: left;
  color: #000000;
}

.AdvicesContainer {
  width: 419px;
  margin: 17px 40px;
  display: flex;
  align-items: center;
}


.AdvicesTextFirst {
  font-family: 'Gotham Pro Regular';
  font-size: 16px;
  line-height: 22px;
  padding-top: 7px;
  color: #000000;
  text-align: left;
}

.AdvicesLetterImage {
  width: 370px;
}

@media screen and (max-width: 980px) {
  .AdvicesContainer {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .AdvicesImage {
    width: 51px;
    margin: 0;
  }

  .AdvicesTextFirst {
    padding-left: 22px;
    width: 278px;
  }

  .AdvicesText {
    padding-left: 22px;
    width: 252px;
  }

  .AdvicesLetterImage {
    width: 306px;
  }
}