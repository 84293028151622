.CodeActivationBlock {
  width: 100%;
  height: 100%;
  margin: 0;
  background: #FFF;
}

body {
  background: #F6F3F2;
}

.CodeActivationUpper {
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.CodeActivationMainIcon {
  width: 114px;
  padding-top: 24px;
  height: 80px;
}

.CodeActivationTitle {
  width: 100%;
  height: 44px;
  font-family: 'Gilroy Semibold';
  font-size: 36px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.CodeActivationInputBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 10px;
}

.CodeActivationInput {
  width: 300px;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 12px 20px;
}

.CodeActivationAdditionalText {
  font-family: 'Gotham Pro Regular';
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 259px;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.9);
  opacity: 0.7;
}

.CodeActivationError {
  font-family: 'Gilroy Semibold';
  font-size: 19px;
  line-height: 20px;
  min-height: 20px;
  text-align: center;
  padding-bottom: 42px;
  color: #a10909;
}

.CodeActivationBuy {
  width: 100%;
  background: #F6F3F2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProstoAppIconMain {
  width: 35px;
  height: 46px;
  display: block;
  margin: 0 auto;
  padding-top: 29px;
  padding-bottom: 26px;
}

.CodeActivationPresent {
  width: 96px;
  height: 96px;
  padding: 70px 0 40px 0;
}

.CodeActivationBuyButton {
  padding-bottom: 70px;
}

@media screen and (max-width: 414px) {
  .CodeActivationInput {
    width: 95%;
  }

  .CodeActivationTitle {
    width: 90%;
    padding-bottom: 50px;
  }

}
