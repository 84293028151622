.Root {
  margin: 18px 0 25px 70px;
}

.RadioItem {
  display: flex;
  margin-left: 73px;
  margin-top: 10px
}

.RadioItem:first-child {
  margin-top: unset;
}

.RadioInput {
  /*font: unset;*/
  width: 15px;
  height: 100%;
  margin: 0;
  color: #AB9E98;
}

input:focus ~ .RadioInput {
  background-color: transparent !important;
}

.Text {
  margin-left: 7px;
  font-family: 'Gotham Pro Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
