.MainBottomBlock {
  height: 100%;
  width: 100%;
  display: flex;
  background: #FFFFFF;
  align-items: center;
  flex-direction: column;
}

.Title {
  width: 317px;
  height: 44px;
  font-family: 'Gilroy Semibold';
  font-size: 36px;
  margin-top: 50px;
  margin-bottom: 39px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.BottomMainBlockButton {
  margin-top: 7px;
}