.Root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MainBlock {
  margin: 0;
  width: 100%;
  padding-bottom: 85px;
  padding-top: 100px;
  display: flex;
  justify-content: center;
}

.ProstoAppIcon {
  margin-left: 50%;
  width: 35px;
  height: 46px;
  top: 22px;
  left: -16px;
  background-size: contain;
  background-image: url("./resources/ProstoappIcon.png");
  background-repeat: no-repeat;
  position: absolute;
}

.BackgroundImage {
  margin-left: 50%;
  width: 455px;
  height: 622px;
  top: 220px;
  left: -571px;
  background-size: contain;
  background-image: url("./resources/UpperCorporateBlockBackground.png");
  background-repeat: no-repeat;
  position: absolute;
}

.MainTitle {
  font-family: 'Gilroy Semibold';
  font-size: 42px;
  line-height: 51px;
  color: #000000;
  margin: 0;
  overflow-wrap: anywhere;
}

.AdditionalText {
  font-family: 'Gotham Pro Regular';
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.MainContainer {
  padding: 0 50px;
  z-index: 10;
}

.TextContainer, .FakeTextContainer {
  width: 320px;
  padding-top: 100px;
}

.HowItWorks {
  display: none;
}

@media screen and (max-width: 980px) {
  .MainBlock {
    height: auto;
    margin: 0;
    max-width: 600px;
    padding-bottom: 0;
    padding-top: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .BackgroundImageContainer {
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    height: 504px;
  }

  .BackgroundImage {
    flex-shrink: 0;
    width: 344px;
    height: 351px;
    top: 102px;
    left: 0;
    margin: 0;
    padding: 0 0 65px;
    background-image: url("./resources/UpperCorporateBlockBackgroundMini.png");
    position: relative;
    display: flex;
  }

  .MainTitle {
    height: auto;
    width: 100%;
    font-size: 36px;
    padding-top: 113px;
    margin-bottom: 0;
  }

  .ProstoAppIcon {
    margin-left: 50%;
    width: 282px;
    height: 285px;
    top: 0;
    left: -19%;
    background-size: contain;
    background-image: url("./resources/IconWithCircles.png");
    background-repeat: no-repeat;
    position: absolute;
  }

  .AdditionalText {
    width: 307px;
    margin: 0 auto;
    padding-top: 15px;
    height: 144px;
  }

  .MainContainer {
    padding: 0;
  }

  .TextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding-top: 0;
  }

  .FakeTextContainer {
    display: none;
  }

  .HowItWorks {
    display: block;
    font-family: 'Gilroy Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding: 20px 0 30px 0;
    text-align: center;
    color: #33B49D;
    text-decoration: none;
  }
}