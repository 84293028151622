@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.FooterBlock {
  width: 100%;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 60px 0;
}

.FooterText {
  font-family: 'Roboto', sans-serif;;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  color: #000000;
}

.FooterButtonContainer {
  display: flex;
  flex-direction: row;
}

.googleContainer {
  width: 157px;
  height: 59px;
}

.iosContainer {
  background:url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2020-01-29&kind=iossoftware&bubble=apple_music) no-repeat;
  width: 135px;
  height: 40px;
  margin-top: 9px;
}

.FooterMail {
  color: #33B49D;
}