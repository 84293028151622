.UpperMainRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 108px;
}

.MainBlock {
  margin: 0;
  padding-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.ProstoAppIcon {
  margin-left: 50%;
  width: 35px;
  height: 46px;
  top: 22px;
  left: -16px;
  background-size: contain;
  background-image: url("./resources/ProstoappIcon.png");
  background-repeat: no-repeat;
  position: absolute;
}

.BackgroundImage {
  margin-left: 50%;
  width: 750px;
  height: 678px;
  top: 0;
  left: -935px;
  z-index: -1;
  background-size: contain;
  transform: scaleX(-1);
  background-image: url("./resources/background.svg");
  background-repeat: no-repeat;
  position: absolute;
}

.MainTitle {
  font-family: 'Gilroy Semibold';
  font-size: 42px;
  line-height: 51px;
  color: #EB7D8F;
  margin-top: 0;
}

.AdditionalText {
  font-family: 'Gotham Pro Regular';
  font-size: 16px;
  line-height: 24px;
  color: #EB7D8F;
}

.MainContainer {
  padding: 0 50px;
}

.TextContainer, .FakeTextContainer {
  width: 307px;
  padding-top: 60px;
}

.HowItWorks {
  display: none;
}

@media screen and (max-width: 980px) {
  .UpperMainRoot {
    padding-bottom: 0;
  }

  .MainBlock {
    margin: 0;
    width: 100%;
    display: flex;
    padding-top: 0;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-bottom: 350px;
  }

  .BackgroundImageContainer {
    width: 100vw;
    margin-right: 24px;
    display: flex;
    justify-content: center;
    z-index: -1;
  }

  .BackgroundImage {
    flex-shrink: 0;
    width: 430px;
    height: 400px;
    left: 38px;
    margin: 0;
    padding: 0 0 65px;
    z-index: -3;
    transform: scaleX(1);
    position: relative;
    display: flex;
  }

  .MainTitle {
    height: auto;
    width: 307px;
    font-size: 36px;
    line-height: 41px;
    margin: 0 auto;
    padding: 0 20px 0 20px;
    text-align: center;
    padding-top: 96px;
  }

  .AdditionalText {
    width: 70%;
    margin: 0 auto;
    padding-top: 15px;
    height: auto;
  }

  .ProstoAppIcon {
    margin-left: 50%;
    width: 282px;
    height: 285px;
    top: 0;
    left: -85px;
    background-size: contain;
    background-image: url("./resources/IconWithCircles.svg");
    background-repeat: no-repeat;
    position: absolute;
  }

  .MainContainer {
    padding: 0;
  }

  .TextContainer {
    display: block;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding-top: 220px;
    z-index: 1000;
  }

  .FakeTextContainer {
    display: none;
  }

  .HowItWorks {
    display: block;
    font-family: 'Gilroy Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding: 20px 0 30px 0;
    text-align: center;
    color: #EB7D8F;
    opacity: 0.55;
    text-decoration: none;
  }
}
