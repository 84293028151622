@font-face {
  font-family: "Gilroy Medium";
  src: url("../../assets/fonts/Gilroy-Medium.eot");
  src: url("../../assets/fonts/Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Gilroy-Medium.woff") format("woff"),
    url("../../assets/fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy Semibold";
  src: url("../../assets/fonts/Gilroy-SemiBold.eot");
  src: url("../../assets/fonts/Gilroy-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Gilroy-SemiBold.woff") format("woff"),
    url("../../assets/fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy Bold";
  src: url("../../assets/fonts/Gilroy-Bold.eot");
  src: url("../../assets/fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Gilroy-Bold.woff") format("woff"),
    url("../../assets/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro Regular";
  src: url("../../assets/fonts/GothamPro.eot");
  src: url("../../assets/fonts/GothamPro.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/GothamPro.woff") format("woff"), url("../../assets/fonts/GothamPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.CalculatorBlock {
  /* height: 572px; */
  width: 450px;
  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(171, 158, 152, 0.4);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CalculatorTitle {
  padding: 40px 0px 25px 0px;

  font-family: "Gilroy Semibold";
  font-size: 28px;
  text-align: center;
  font-weight: 600;
  line-height: 34px;
  align-self: center;
  margin: 0 auto;

  color: #000000;
}

Input {
  font-family: "Gotham Pro Regular";
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0 20px 0 20px;
  width: 100%;
  height: 56px;
}

input:focus {
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: none;
}

.CalculatorAdditionalText {
  font-family: "Gotham Pro Regular";
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding-top: 10px;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.9);
  opacity: 0.7;
}

.CalculatorText {
  font-family: "Gotham Pro Regular";
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.CalculatorBottomBlock {
  flex-direction: row;
  display: flex;
  justify-content: center;
}

.CalculatorBottomLeftBlock {
  flex: auto;
  padding-left: 70px;
}

.CalculatorBottomRightBlock {
  padding-right: 50px;
  text-align: right;
}

.CalculatorTextFirst {
  font-family: "Gotham Pro Regular";
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin: 33px 0 36px 0;
}

.CalculatorOffer {
  padding-top: 15px;
  width: 250px;
  font-family: "Gotham Pro Regular";
  height: 48px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  margin: 0 auto;
}

.CalculatorLink {
  color: #33b49d;
  text-decoration: none;
}
.CalculatorLink:hover {
  text-decoration: underline;
}

.CalculatorFooter {
  padding-bottom: 40px;
}

.CalculatorBottomRightBlockText {
  padding-right: 20px;
}

.RubleIcon {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

@media screen and (max-width: 414px) {
  .CalculatorBlock {
    width: 100%;
  }

  .Email {
    width: 90%;
    height: 56px;
    margin: 0 auto;
    display: block;
  }

  .CalculatorBottomLeftBlock {
    flex: auto;
    padding-left: 30px;
  }

  .CalculatorBottomRightBlock {
    padding-right: 30px;
    text-align: right;
  }

  .CalculatorFooter {
    padding-bottom: 40px;
    width: 90%;
    margin: 0 auto;
  }
}
