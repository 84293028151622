.CertificateMainBlock {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.CertificateImage {
  width: auto;
  height: 50px;
}

.CertificateText {
  font-family: 'Gotham Pro Regular';
  font-size: 16px;
  line-height: 22px;
  padding-top: 7px;
  color: #000000;
}
.CertificateContainer {
  width: 287px;
  margin: 17px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


.CertificateTextFirst {
  font-family: 'Gotham Pro Regular';
  font-size: 16px;
  line-height: 22px;
  padding-top: 7px;
  color: #000000;
  width: 218px;
}