@font-face {
  font-family: 'Gilroy Medium';
  src: url('../../assets/fonts/Gilroy-Medium.eot');
  src: url('../../assets/fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/Gilroy-Medium.woff') format('woff'),
  url('../../assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Semibold';
  src: url('../../assets/fonts/Gilroy-SemiBold.eot');
  src: url('../../assets/fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/Gilroy-SemiBold.woff') format('woff'),
  url('../../assets/fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Bold';
  src: url('../../assets/fonts/Gilroy-Bold.eot');
  src: url('../../assets/fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/Gilroy-Bold.woff') format('woff'),
  url('../../assets/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.CustomButtonBlock {
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.CustomButton {
  font-family: 'Gilroy Semibold';
  font-size: 19px;
  width: 350px;
  line-height: 22px;
  height: 56px;
  border: none;
  background: #BEB1AB;
  border-radius: 16px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  flex-direction: row;
  outline: none;
  cursor: pointer;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.CustomButton:active {
  background:#AB9E98;
  outline: none;
}

.CustomButton:disabled {
  background:#F3F3F3;
  outline: none;
}


.DownloadIcon {
  width: 16px;
  height: 20px;
  padding-right: 12px;
}